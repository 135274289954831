html {
  overflow: hidden;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* HEADER */

.header {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 96px;
  background: #041146;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 96px;
  right: 0px;
  top: calc(50% - 96px/2 - 0px);
  background: #041146;
}

.logo {
  width: 50px;
  height: 20px;
}

.navItem {
  align-items: center;
  justify-content: center;
  width: 140px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  background: #041146;
  padding-bottom: 6px;
  border: none;
  border-top: 6px solid transparent;
}

.navItem:active,
.navItem:focus,
.navItem:hover {
  outline: none;
  text-decoration: none;
}

.activeTab {
  color: #009CD8;
  border-top: 6px solid #009CD8;
}

.navText {
  align-items: center;
  justify-content: center;
  /* width: 200px; */
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  margin-left: auto;
  /* margin-right: 30px; */
}

.highlight {
  font-weight: bold;
  font-size: 16px;
}

.wrap{
  display: flex;
}
.wrap.active{
  display: flex;
}

/* LOGIN */

.login {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  text-align: center;
}

.rectangleContainer {
  position: absolute;
  width: 552px;
  /* height: 388px; */
  height: 300px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.rectangle {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0.04%;
}

.loginHeadline {
  font-size: 26px;
  /* margin-top: 75px; */
  margin-top: 45px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 360px;
  height: 106px;
  margin: auto;
  /* margin-bottom: 49px; */
  margin-top: 40px;

}

/* TABLE */

.table {
  overflow-y: auto;
  margin: 0 auto;
  /* max-height: 60%; */
  border: 1px solid #ddd;
  /* box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.07), 0px 9.19107px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 4.91399px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.75474px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.46302px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.608796px 2.21381px rgba(0, 0, 0, 0.0196802);
  height: fit-content; */
  text-align: left;
}
/* 
.table tr:first-child th{
  border-top: initial;
}
.table td, .table th {
  padding: 7px;
  vertical-align: middle;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.table thead th {
 border-bottom: 2px solid #dee2e6;
}

.table thead {
  box-shadow: 0 2px 3px 0 #ced0d3;
} */

.tableContainer {
  margin: 0 auto;
  width: 100%;
  height: fit-content;
}

.titleContainer {
  width: 97%;
  margin: 0 auto;
  display: flex;
}

.title {
  text-align: left;
  font-weight: bold;
  /* font-size: 18px; */
  color: white;
  margin: 0 10px 6px 10px;
}

.options {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  background-color: white;
  padding: 8px;
}

.tableRow {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.innerTableContainer {
  background-color: #f4f4f4;
  padding: 16px;
  text-align: left;
}

.innerTableName {
  margin: 6px;
  text-decoration: underline;
}

.tableText {
  font-size: 15px;
  line-height: 16px;
  color: #4C556A;
}
.add-group-popup{
  display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    left: 0;
    width: 62%;
    height: 43%;
    background-color: white;
}
.popup-title{
  margin-top: 32px !important;
    margin-bottom: 32px
}

/* BUTTON */

.button {
  min-width: 85px;
  padding: 11px;
  background: #009CD8;
  border-radius: 2px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.07em;
  /* text-transform: uppercase; */
  color: #FAFAFA;
}

.button-sm {
  width: auto;
  height: auto;
  font-size: 10px;
  line-height: 12px;
}

.button:hover {
  background: #1299ce;
}

.button:active, 
.button:focus {
  outline: none;
}

.outlineBlue {
  background-color: white;
  border: 1px solid #009CD8;
  color: #009CD8
}

.outlineGrey {
  background-color: white;
  border: 1px solid #4C556A;
  color: #4C556A;
}

.disabled {
  /* background-color: #ccc;
  border: 1px solid #c0c1c4; */
  color: #888;
}

/* POPUP */

.popup {
  width: 800px;
  /* height: 500px; */
  height: fit-content;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 32px 0;
}

h1 {
  color: #009CD8;
  font-size: 24px;
  margin: 0;
}

.insert-container {
  height: 300px;
}

.insert-container:hover,
.insert-container:active {
  border: 1px solid #009CD8;
}

.upload-file-container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file-container:hover {
  border: 1px solid #009CD8;
}

.upload-file {
  height: 150px;
  width: 80%;
  background: #FAFAFA;
  position: relative;
}
.tool-tip{
  position: absolute;
  background-color: white;
  padding: 10px;
  margin: auto;
  width: 320px;
  bottom: 63%;
  border: 1px solid #009CD8;
  font-size: 13px;
  box-shadow: -1px 13px 33px -23px rgba(0,156,216,1);
  text-align: left;
  visibility: hidden;
  z-index: 11111;
}
.selected .upload-file:hover .tool-tip{
  visibility: visible;
}
.insert-container.tags-tooltip:hover .tool-tip{
  visibility: visible;
}
.font-ex{
  font-family: 'Montserrat', sans-serif;
}

.file-input{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0;
}

.arrow-back {
  position: absolute;
  top: 32px;
  left: 26px;
}

.selected {
  border: 1px solid #009CD8;
}

.input-label {
  text-align: left;
  font-size: 18px;
  color: #424141;
  margin-bottom: 13px;
}

/* input {
  border: initial;
  height: 49px;
  background: #FAFAFA;
  outline: none;
  width: 100%;
  color: #4C556A;
  font-size: 14px;   
} */

.MuiInputBase-input {
  font-size: 14px !important;
}

.container {
  width: 600px;
  display: flex;
  flex-direction: column;
}

.prefix {
  background-color: #FAFAFA;
  flex: initial;
  max-width: initial;
  margin-right: 10px !important;
  padding: 12px 10px !important;
  font-size: 12px;

}
.center{
  align-items: center;
}

/* REUSE */

.backImage {
  background: url('../src/assets/images/login_bg_Image.png');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.blueBackImage {
  background-image:linear-gradient(rgba(84, 99, 161, 0.7),rgba(84, 99, 161, 0.7)), url('../src/assets/images/login_bg_Image.png');
  background-size: cover;
  background-position: center;
  padding: 12px;
  padding-top: 32px;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
}

@media screen and (min-width: 576px) {
  .blueBackImage { padding-left: 48px; padding-right: 48px; }  
}
@media screen and (min-width: 768px) {
  .blueBackImage { padding-left: 96px; padding-right: 96px; }
}

.label {
  margin-top: 18px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: right;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.field, .field:focus {
  position: static;
  width: 360px;
  height: 25px;
  left: 0px;
  top: 0px;
  text-align: left;
  border: none;
  border-bottom: 1px solid #8F95A2;    
  background-color: white;
  outline: none;
}

.loginButton {
  font-weight: 700;
  color: white;
  width: 104px;
  height: 40px;
  left: calc(50% - 104px/2);
  bottom: 54px;
  background: #009CD8;
  border-radius: 2px;
  border: none;
  padding: 10px 20px;
}
.grey {
  color: #8F95A2;
}

.lightBlue {
  color: #009CD8;
}

.whiteBack {
  background: #FFFFFF;
}

.lightGrey {
  background-color: #ebebeb;
}

.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.scrollY {
  overflow-y: scroll;
}

.box {
  box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.07), 0px 9.19107px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 4.91399px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.75474px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.46302px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.608796px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 2px;
}

.textMuted {
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
  color: #888;
}

.checkBox {
  width: 16px;
  height: 16px;
}

.form-check-label {
  /* margin-top: 5px; */
  margin-right: 5px;
}

.noBorder,
.noBorder:active,
.noBorder:focus {
  border: none;
  outline: none;
}

.paginationText {
  padding-top: 3px;
  font-size: 13px;
  line-height: 16px;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #a0a7ad;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #ececec;
}

.table td, .table th {
  vertical-align: middle;
}

.btn-brand {
  color: #fff;
  background-color: #009cd8;
  border-color: #009cd8;
}
.btn-brand:hover, .btn-brand:active {
  color: #fff;
  background-color: #0069d9;
  border-color: #009cd8;  
}
.btn-brand.focus, .btn-brand:focus {
  box-shadow: 0 0 0 .2rem rgba(38,143,255,.5);
}
.btn-outline-brand {
	color: #009cd8;
	border-color: #009cd8;
}
.btn-outline-brand:hover, .btn-outline-brand:active {
  color: #fff;
  background-color: #009cd8;
  border-color: #009cd8;
}
.btn-outline-brand.focus, .btn-outline-brand:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
}

.icon-svg {
  display: inline-block;
  width: 32px;
  height: 32px;
}
.rotate-90 { transform: rotate(90deg); }
.rotate-180 { transform: rotate(180deg); }
.rotate-270 { transform: rotate(270deg); }
.faded { opacity: 0.2; }

.modal-body { overflow: auto; }

.error-msg {
  position: absolute;
  top: 20px;
  left: calc(50% - 200px);
  border: 1px solid #777;
  z-index: 10;
  max-width: 400px;
  transition: opacity 2s;
}
.error-msg.fadeout {
  opacity: 0;
}
.error-x {
  border: 2px solid red;
  border-radius: 50%;
  padding: 3px;
  margin-right: 4px;
}