
.table-container {
	border: 1px solid #ddd;
	background-color: white;
}

.table-topbar {
	padding: 0.5rem 1rem;
}

.table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.table-header .dropdown-menu {
	min-width: 8rem;
}

.table-title {
	font-weight: bold;
}

.table-actions {
	display: flex;
	flex-direction: row;
}

.table-checkbox {
	width: 25px;
}

.table-search {
	min-width: 300px;
	max-width: 650px;
}

.table-subtitle {
	font-size: 14px;
	color: #888;
	font-weight: normal;
}

.table-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pagination-text {
	font-family: Arial;
	font-size: 0.9rem;
}

.col-fit-content {
	width: 1%;
	white-space: nowrap;
}

.select-cols-item {
	display: inline-block;
	min-width: 200px;
	margin: 1px 10px;
	padding: 2px;
}