body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

html,body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  width: 100%;
}